<!--
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2022-08-12 10:51:50
 * @LastEditors: faf
 * @Description: 区域营收对比
-->
<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent>
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item :label="$t('searchModule.region')" prop="areaId">
          <a-cascader ref="cascader" @change="parkClear"></a-cascader>
        </el-form-item> -->
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.charge_type')">
          <a-park-type-select
            ref="parkTypeSelect"
            @change="parkClear"
          ></a-park-type-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Vehicle_type')">
          <a-park-type-select
            ref="parkTypeSelect"
            @change="parkClear"
          ></a-park-type-select>
        </el-form-item> -->
              <!-- <el-form-item :label="$t('searchModule.park_name')">
          <a-park-select
            ref="parkSelect"
            :instance="this"
            parkTypeRefName="parkTypeSelect"
            operationRefName="operationSelect"
            areaRefName="cascader"
          ></a-park-select>
        </el-form-item> -->
              <el-form-item label="时间">
                <a-date-picker
                  ref="datePicker"
                  :selectkeys="selectkeys"
                  :YearShowHidden="selectkeys[3]"
                  :isPreviousday="isPreviousday"
                ></a-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData()"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="clearData('formInline')"
                :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportExcelReport()"
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="content">
        <div class="top bgFFF paddingLR20 marginT20 header-wrapper">
          <graphTitle :title="'区域营收汇总统计'"></graphTitle>
        </div>
        <div class="tableWrapper bgFFF paddingB10">
          <el-table stripe v-loading="loading" :data="tableData_" class="table-content">
            <el-table-column
              align="center"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table>
        </div>
        <div class="echarts-wrapper">
          <el-row :gutter="16">
            <el-col :span="12"
              ><div class="echarts-item">
                <div class="top paddingLR20 marginT20 header-wrapper">
                  <graphTitle :title="'应收订单数与实收订单数'">
                    <el-tooltip
                      placement="top"
                      content="展示统计时间内，各区域已出场的停车订单与已完成缴费的订单数量对比及趋势。按出场时间统计。未出场订单不参与统计。"
                    >
                      <i class="el-icon-question my-icon" />
                    </el-tooltip>
                  </graphTitle>
                  <!-- <span>导出</span> -->
                </div>
                <received-order :type="1" :chartParam="chart1Param"></received-order></div
            ></el-col>
            <el-col :span="12"
              ><div class="echarts-item">
                <div class="top paddingLR20 marginT20 header-wrapper">
                  <graphTitle :title="'应收金额与实收金额'">
                    <el-tooltip
                      placement="top"
                      content="展示统计时间内，各区域已出场的停车订单应收金额与实收金额的对比及趋势。实收金额=优惠金额+实付金额-退款金额。未出场订单不参与统计"
                    >
                      <i class="el-icon-question my-icon" />
                    </el-tooltip>
                  </graphTitle>
                  <!-- <span>导出</span> -->
                </div>
                <received-order :type="2" :chartParam="chart2Param"></received-order></div
            ></el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="12"
              ><div class="echarts-item">
                <div class="top paddingLR20 marginT20 header-wrapper">
                  <graphTitle :title="'订单实缴率对比分析'">
                    <el-tooltip
                      placement="top"
                      content="订单实缴率=实收订单数/应收订单数。该指标反应各区域订单的实缴情况。指标越高，说明订单缴费情况越好。如一笔停车订单即有场中支付也有欠费，那么订单实缴率为0。"
                    >
                      <i class="el-icon-question my-icon" />
                    </el-tooltip>
                  </graphTitle>
                  <!-- <span>导出</span> -->
                </div>
                <paid-rate-analysis :type="3" :chartParam="chart3Param"></paid-rate-analysis></div
            ></el-col>
            <el-col :span="12"
              ><div class="echarts-item">
                <div class="top paddingLR20 marginT20 header-wrapper">
                  <graphTitle :title="'金额实缴率对比分析'">
                    <el-tooltip
                      placement="top"
                      content="金额实缴率=实收订单金额/应收订单金额。该指标反应各区域订单的金额实缴情况。指标越高，说明订单缴费情况越好。"
                    >
                      <i class="el-icon-question my-icon" />
                    </el-tooltip>
                  </graphTitle>
                  <!-- <span>导出</span> -->
                </div>
                <paid-rate-analysis :type="4" :chartParam="chart4Param"></paid-rate-analysis></div
            ></el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="12"
              ><div class="echarts-item">
                <div class="top paddingLR20 marginT20 header-wrapper">
                  <graphTitle :title="'客单价'">
                    <el-tooltip
                      placement="top"
                      content="客单价=订单应收金额/停车订单数。展示统计范围内各区域客单价的对比及与全区域平均值的对比。"
                    >
                      <i class="el-icon-question my-icon" />
                    </el-tooltip>
                  </graphTitle>
                  <!-- <span>导出</span> -->
                </div>
                <unit-price :type="5" :chartParam="chart5Param"></unit-price></div
            ></el-col>
            <el-col :span="12"
              ><div class="echarts-item">
                <div class="top paddingLR20 marginT20 header-wrapper">
                  <graphTitle :title="'平均泊位收入'">
                    <el-tooltip
                      placement="top"
                      content="平均泊位收入=实收金额/泊位数量。展示统计范围内各区域平均泊位收入的对比及与全区域平均值的对比"
                    >
                      <i class="el-icon-question my-icon" />
                    </el-tooltip>
                  </graphTitle>
                  <!-- <span>导出</span> -->
                </div>
                <unit-price :type="6" :chartParam="chart6Param"></unit-price></div
            ></el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getZeroOrLastDateTime } from "@/common/js/utils";
import graphTitle from "@/components/graphTitle";
import receivedOrder from "@/components/parkingFeeAnalysis/receivedOrder";
import paidRateAnalysis from "@/components/parkingFeeAnalysis/paidRateAnalysis";
import unitPrice from "@/components/parkingFeeAnalysis/unitPrice";
import { exportExcelNew, formatWeekDate } from "@/common/js/public.js";
export default {
  name: "arrearageHistory",
  data() {
    const startTime = getZeroOrLastDateTime("yyyy-MM-dd", -31);
    const endTime = getZeroOrLastDateTime("yyyy-MM-dd", -1);
    return {
      defaultTime: [startTime, endTime],
      tableData_: [],
      chart1Param: {
        legendData: ["应收订单数", "实收订单数"],
        unit: "单位：个",
        xData: [],
        yData: [],
        barData: [],
      },
      chart2Param: {
        legendData: ["应收金额", "实收金额"],
        unit: "单位：元",
        xData: [],
        yData: [],
        barData: [],
      },
      chart3Param: {
        unit: "订单实缴率",
        xData: [],
        barData: [],
      },
      chart4Param: {
        unit: "金额实缴率",
        xData: [],
        barData: [],
      },
      chart5Param: {
        unit: "单位：元",
        xData: [],
        yData: [],
      },
      chart6Param: {
        unit: "单位：元",
        xData: [],
        yData: [],
      },
      // 区域列表
      areaList: [],
      isPreviousday: true,
      selectkeys: ["date", "month", "week", "year", "datetime"],
      // 区域街道
      areaList2: [],
      businessTypes: [],
      loading: false,
      tableData: [],
      type1: "c1",
      formInline: {
        // 区域
        areaId: "",
        // 区域
        streetId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
        // 0元选项
        isZero: "0",
        dateType: "day",
        startTime: "",
        endTime: "",
      },
      tableCols: [
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "auto",
          // formatter: (row) => {
          //   if (row.dataDate) {
          //     // 周粒度要单独处理
          //     if (this.$refs.datePicker.getDateType() == 3) {
          //       return formatWeekDate(row.dataDate);
          //     } else {
          //       return row.dataDate;
          //     }
          //   }
          // },
        },
        {
          prop: "shouldpayNum",
          label: "应收订单数",
          width: "",
          formatter: (row) => {
            return row.shouldpayNum ? row.shouldpayNum : 0;
          },
        },
        {
          prop: "actualpayNum",
          label: "实收订单数",
          width: "",
          formatter: (row) => {
            return row.actualpayNum ? row.actualpayNum : 0;
          },
        },
        {
          prop: "arrearageNum",
          label: "欠费订单数",
          width: "",
          formatter: (row) => {
            return row.arrearageNum ? row.arrearageNum : 0;
          },
        },
        {
          prop: "actualPayNumRate",
          label: "订单实缴率",
          width: "",
          formatter: (row) => {
            return row.actualPayNumRate > 0
              ? (row.actualPayNumRate * 100).toFixed(2) + "%"
              : "0.00%";
          },
        },
        {
          prop: "shouldpay",
          label: "应收金额（元）",
          width: "",
          formatter: (row) => {
            if (row.shouldpay) {
              // let payNum = Number(row.shouldPay / 100).toFixed(2);
              return Number(row.shouldpay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualpay",
          label: "实收金额（元）",
          width: "",
          formatter: (row) => {
            if (row.actualpay) {
              return Number(row.actualpay / 100).toFixed(2);
              // let payNum = Number(row.actualpay / 100).toFixed(2);
              // return (
              //   payNum.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") +
              //   "." +
              //   payNum.split(".")[1]
              // );
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "arrearageAmount",
          label: "欠费金额（元）",
          width: "",
          formatter: (row) => {
            if (row.arrearageAmount) {
              return Number(row.arrearageAmount / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualPayMoneyRate",
          label: "金额实缴率",
          width: "",
          formatter: (row) => {
            return row.actualPayMoneyRate > 0
              ? (row.actualPayMoneyRate * 100).toFixed(2) + "%"
              : "0.00%";
          },
        },
        {
          prop: "avgBerthIncome",
          label: "平均泊位收入",
          width: "",
          formatter: (row) => {
            if (row.avgBerthIncome) {
              return Number(row.avgBerthIncome / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "customerUnitPrice",
          label: "客单价",
          width: "",
          formatter: (row) => {
            if (row.customerUnitPrice) {
              return Number(row.customerUnitPrice / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
    };
  },
  methods: {
    parkClear() {
      // this.$refs.parkSelect.clear();
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    clearData(formname) {
      this.$refs[formname].resetFields();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      // this.$refs.parkSelect.clear();
      this.searchData();
    },

    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    dateType() {
      let dateStr = "";
      if (this.formInline.month) {
        dateStr = this.dateFormat(this.formInline.month, "yyyy-MM");
      } else {
        dateStr = "";
      }
      return dateStr;
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 格式化日期
    dateFormat(date, fmt) {
      if (!(date instanceof Date)) {
        return "";
      }
      fmt = fmt || "yyyy-MM-dd HH:mm:ss";
      var o = {
        // 月份
        "M+": date.getMonth() + 1,
        // 日
        "d+": date.getDate(),
        // 小时
        "H+": date.getHours(),
        // 分
        "m+": date.getMinutes(),
        // 秒
        "s+": date.getSeconds(),
        // 季度
        "q+": Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 查询
    searchData() {
      this.loading = true;
      this.chart1Param.xData = [];
      this.chart1Param.barData = [];
      this.chart1Param.yData = [];
      this.chart2Param.xData = [];
      this.chart2Param.barData = [];
      this.chart2Param.yData = [];
      this.chart3Param.xData = [];
      this.chart3Param.barData = [];
      this.chart4Param.xData = [];
      this.chart4Param.barData = [];
      this.chart5Param.xData = [];
      this.chart5Param.yData = [];
      this.chart6Param.xData = [];
      this.chart6Param.yData = [];
      this.$axios
        .get("/acb/2.0/areaRevenueAnalysis/collect", {
          data: {
            parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            // isZero: this.formInline.isZero,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          this.tableData = res.value;
          // 周粒度需要单独处理
          this.tableData.forEach((item) => {
            if (this.$refs.datePicker.getDateType() == 3) {
              if (item.dataDate) {
                item._dataDate = formatWeekDate(item.dataDate);
              } else {
                item._dataDate = item.dataDate;
              }
            } else {
              item._dataDate = item.dataDate;
            }
          });
          this.tableData_ = this.tableData;

          res.value.forEach((val) => {
            if (val.areaName !== "全部") {
              // 应收订单数与实收订单数
              this.chart1Param.xData.push(val.areaName);
              this.chart1Param.barData.push(val.actualpayNum || 0);
              this.chart1Param.yData.push(val.shouldpayNum || 0);
              // 应收金额与实收金额
              this.chart2Param.xData.push(val.areaName);
              this.chart2Param.barData.push(
                val.actualpay ? Number(val.actualpay / 100).toFixed(2) : "0.00"
              );
              this.chart2Param.yData.push(
                val.shouldpay ? Number(val.shouldpay / 100).toFixed(2) : "0.00"
              );
              // 订单实缴率对比分析
              this.chart3Param.xData.push(val.areaName);
              this.chart3Param.barData.push(val.actualPayNumRate);
              // 金额实缴率对比分析
              this.chart4Param.xData.push(val.areaName);
              this.chart4Param.barData.push(val.actualPayMoneyRate);
              // 客单价
              this.chart5Param.xData.push(
                val.customerUnitPrice ? Number(val.customerUnitPrice / 100).toFixed(2) : "0.00"
              );
              this.chart5Param.yData.push(val.areaName);
              // 平均泊位收入
              this.chart6Param.xData.push(
                val.avgBerthIncome ? Number(val.avgBerthIncome / 100).toFixed(2) : "0.00"
              );
              this.chart6Param.yData.push(val.areaName);
            } else {
              // 平均泊位收入 平均值取表格全部里的数据
              this.chart5Param.yData = ["平均值"];
              this.chart6Param.yData = ["平均值"];
              this.chart5Param.xData.push(
                val.customerUnitPrice ? Number(val.customerUnitPrice / 100).toFixed(2) : "0.00"
              );
              this.chart6Param.xData.push(
                val.avgBerthIncome ? Number(val.avgBerthIncome / 100).toFixed(2) : "0.00"
              );
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 导出
    exportExcelReport() {
      // let flag = this.showLog();
      // if (!flag) return;
      let dateNum = 1;
      if (this.formInline.dateType == "day") {
        dateNum = 1;
      } else {
        dateNum = 2;
      }
      let opt = {
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        // isZero: this.formInline.isZero,
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      exportExcelNew("/acb/2.0/financeStatistic/exportPrice", opt, "post");
    },
  },
  created() {
    this.getArea();
    // this.searchData();
    // this.getTimeNow();
  },
  mounted() {
    this.searchData();
  },
  components: {
    graphTitle,
    receivedOrder,
    paidRateAnalysis,
    unitPrice,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  .table-content {
    /deep/ thead {
      width: 100%;
      height: 54px;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px 4px 0px 0px;

      th {
        background-color: #F3F7FF;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        font-size: 14px;
      }
    }
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 10px;
  }
}

.echarts-wrapper {
  margin-top: 16px;

  .echarts-item {
    background: #FFFFFF;
    border-radius: 8px;
  }
}
</style>
