var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "status-icon": "",
                "label-position": "right",
                model: _vm.formInline,
              },
            },
            [
              _c("div", { staticClass: "search_box_title" }, [
                _vm._v(_vm._s(_vm.$t("searchModule.Query_Table"))),
              ]),
              _c("div", { staticClass: "col_box" }, [
                _c(
                  "div",
                  { staticClass: "col_left" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Belonging_operator"),
                        },
                      },
                      [
                        _c("a-operation-select", {
                          ref: "operationSelect",
                          on: { change: _vm.parkClear },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Type_of_parking_lot"),
                        },
                      },
                      [
                        _c("a-park-type-select", {
                          ref: "parkTypeSelect",
                          on: { change: _vm.parkClear },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "时间" } },
                      [
                        _c("a-date-picker", {
                          ref: "datePicker",
                          attrs: {
                            selectkeys: _vm.selectkeys,
                            YearShowHidden: _vm.selectkeys[3],
                            isPreviousday: _vm.isPreviousday,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col_right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.searchData()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.search")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "info",
                          icon: "el-icon-delete",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.clearData("formInline")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.reset")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col_box_boder" }),
              _c("div", { staticClass: "col_box h44" }, [
                _c("div", { staticClass: "col_left" }),
                _c(
                  "div",
                  { staticClass: "col_right mbd4" },
                  [
                    _vm.$route.meta.authority.button.query
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "info",
                              icon: "el-icon-upload2",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.exportExcelReport()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.export")))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "top bgFFF paddingLR20 marginT20 header-wrapper" },
          [_c("graphTitle", { attrs: { title: "区域营收汇总统计" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "tableWrapper bgFFF paddingB10" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "table-content",
                attrs: { stripe: "", data: _vm.tableData_ },
              },
              _vm._l(_vm.tableCols, function (item) {
                return _c("el-table-column", {
                  key: item.prop,
                  attrs: {
                    align: "center",
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                    formatter: item.formatter,
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "echarts-wrapper" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 16 } },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "echarts-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "top paddingLR20 marginT20 header-wrapper",
                        },
                        [
                          _c(
                            "graphTitle",
                            { attrs: { title: "应收订单数与实收订单数" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    content:
                                      "展示统计时间内，各区域已出场的停车订单与已完成缴费的订单数量对比及趋势。按出场时间统计。未出场订单不参与统计。",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question my-icon",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("received-order", {
                        attrs: { type: 1, chartParam: _vm.chart1Param },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "echarts-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "top paddingLR20 marginT20 header-wrapper",
                        },
                        [
                          _c(
                            "graphTitle",
                            { attrs: { title: "应收金额与实收金额" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    content:
                                      "展示统计时间内，各区域已出场的停车订单应收金额与实收金额的对比及趋势。实收金额=优惠金额+实付金额-退款金额。未出场订单不参与统计",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question my-icon",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("received-order", {
                        attrs: { type: 2, chartParam: _vm.chart2Param },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 16 } },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "echarts-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "top paddingLR20 marginT20 header-wrapper",
                        },
                        [
                          _c(
                            "graphTitle",
                            { attrs: { title: "订单实缴率对比分析" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    content:
                                      "订单实缴率=实收订单数/应收订单数。该指标反应各区域订单的实缴情况。指标越高，说明订单缴费情况越好。如一笔停车订单即有场中支付也有欠费，那么订单实缴率为0。",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question my-icon",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("paid-rate-analysis", {
                        attrs: { type: 3, chartParam: _vm.chart3Param },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "echarts-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "top paddingLR20 marginT20 header-wrapper",
                        },
                        [
                          _c(
                            "graphTitle",
                            { attrs: { title: "金额实缴率对比分析" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    content:
                                      "金额实缴率=实收订单金额/应收订单金额。该指标反应各区域订单的金额实缴情况。指标越高，说明订单缴费情况越好。",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question my-icon",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("paid-rate-analysis", {
                        attrs: { type: 4, chartParam: _vm.chart4Param },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 16 } },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "echarts-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "top paddingLR20 marginT20 header-wrapper",
                        },
                        [
                          _c(
                            "graphTitle",
                            { attrs: { title: "客单价" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    content:
                                      "客单价=订单应收金额/停车订单数。展示统计范围内各区域客单价的对比及与全区域平均值的对比。",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question my-icon",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("unit-price", {
                        attrs: { type: 5, chartParam: _vm.chart5Param },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c(
                    "div",
                    { staticClass: "echarts-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "top paddingLR20 marginT20 header-wrapper",
                        },
                        [
                          _c(
                            "graphTitle",
                            { attrs: { title: "平均泊位收入" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    content:
                                      "平均泊位收入=实收金额/泊位数量。展示统计范围内各区域平均泊位收入的对比及与全区域平均值的对比",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question my-icon",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("unit-price", {
                        attrs: { type: 6, chartParam: _vm.chart6Param },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }